import './App.css';
import * as Styled from './appStyled';

function App() {
  return (
    <div className="App">
      <Styled.SectionWrapDiv>
        <Styled.HeaderDiv>
          <img src="/logo.svg" alt="stofee logo" />
        </Styled.HeaderDiv>

        <Styled.TopContentDiv>
          <Styled.TopColumnDiv>
            <Styled.TopHeadingDiv>Fashion designing made fast and easy with AI</Styled.TopHeadingDiv>
            <Styled.TopText1Div>Know what's trending using real data AI based design editing tool and tech pack creation in minutes<br />Get a sample delivered in 7 days!</Styled.TopText1Div>
            <Styled.ContactUsA href="https://forms.gle/JkP3q3CtsLaRnDWe6" target='_blank'>Contact Us</Styled.ContactUsA>
          </Styled.TopColumnDiv>

          <Styled.ImagesDiv>
            <Styled.ImagesLeftDiv />

            <Styled.ImagesRightDiv>
              <Styled.ImagesRightTopDiv />
              <Styled.ImagesRightBottomDiv />
            </Styled.ImagesRightDiv>
          </Styled.ImagesDiv>
        </Styled.TopContentDiv>

      </Styled.SectionWrapDiv>

      <Styled.MiddleSectionDiv>
        <Styled.MiddleHeadingDiv>How does stofee work?</Styled.MiddleHeadingDiv>

        <Styled.MiddleWrapDiv>
          <Styled.MiddleIconDiv>
            <Styled.IconDiv>
              <img src="/icon-1.svg" alt="stofee icon" />
            </Styled.IconDiv>
            <Styled.IconText1Div>Design Faster</Styled.IconText1Div>
            <Styled.IconText2Div>Using AI editor specially made for fashion, design Faster</Styled.IconText2Div>
          </Styled.MiddleIconDiv>

          <Styled.Line1Div />

          <Styled.MiddleIconDiv>
            <Styled.IconDiv>
              <img src="/icon-2.svg" alt="stofee icon" />
            </Styled.IconDiv>
            <Styled.IconText1Div>Automate Documentation</Styled.IconText1Div>
            <Styled.IconText2Div>Get tech pack made in a click using AI</Styled.IconText2Div>
          </Styled.MiddleIconDiv>

          <Styled.Line2Div />

          <Styled.MiddleIconDiv>
            <Styled.IconDiv>
              <img src="/icon-1.svg" alt="stofee icon" />
            </Styled.IconDiv>
            <Styled.IconText1Div>Production</Styled.IconText1Div>
            <Styled.IconText2Div>Connect with curated list of trusted suppliers and manufacturers to get sampling and production done</Styled.IconText2Div>
          </Styled.MiddleIconDiv>

          <Styled.Line3Div />

          <Styled.MiddleIconDiv>
            <Styled.IconDiv>
              <img src="/icon-1.svg" alt="stofee icon" />
            </Styled.IconDiv>
            <Styled.IconText1Div>Collaborate</Styled.IconText1Div>
            <Styled.IconText2Div>Track every step of your order and collaborate with cross functional teams</Styled.IconText2Div>
          </Styled.MiddleIconDiv>
        </Styled.MiddleWrapDiv>

      </Styled.MiddleSectionDiv>

      <Styled.SectionWrapDiv>
        <Styled.BottomContentDiv>
          <Styled.ImageBottomDiv />

          <Styled.BottomColumnDiv>
            <Styled.BottomHeadingDiv>Why work with Stofee?</Styled.BottomHeadingDiv>
            <Styled.BottomText1Div>Stofee is a Canva for fashion designers. We allow you to take designs, iterate and order samples within a few minutes allowing you to focus on innovation instead of repetitive tasks</Styled.BottomText1Div>

            <Styled.BottomListWrapDiv>
              <Styled.BottomListDiv>
                <Styled.BottomIconImg src="/check-icon.svg" />
                <div>End to End platform from design to production</div>
              </Styled.BottomListDiv>

              <Styled.BottomListDiv>
                <Styled.BottomIconImg src="/check-icon.svg" />
                <div>Saves time for innovative tasks</div>
              </Styled.BottomListDiv>

              <Styled.BottomListDiv>
                <Styled.BottomIconImg src="/check-icon.svg" />
                <div>Automates grunt work and repeatative tasks</div>
              </Styled.BottomListDiv>

              <Styled.BottomListDiv>
                <Styled.BottomIconImg src="/check-icon.svg" />
                <div>Ethical suppliers, quality, in time production with best prices</div>
              </Styled.BottomListDiv>
            </Styled.BottomListWrapDiv>
          </Styled.BottomColumnDiv>
        </Styled.BottomContentDiv>

        <Styled.FooterDiv>© 2024 Stofee. All rights reserved.</Styled.FooterDiv>
      </Styled.SectionWrapDiv>

    </div>
  );
}

export default App;
