import styled from "styled-components";

export const MainWrap = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
export const SectionWrapDiv = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const HeaderDiv = styled.div`
  height: 88px;
  display: flex;
  align-items: center;
  padding: 0px 32px;
  border-bottom: 1px solid #E5E6F3;
  flex-shrink: 0;
  & img {
    height: 32px;
    width: 90px;
  }
`;
export const FooterDiv = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  background: #231F20;
  color: #ffffff;
  padding: 0px 80px;
  font-size: 14px;
  line-height: 21px;
  flex-shrink: 0;
`;
export const TopContentDiv = styled.div`
  min-height: calc(100vh - 88px);
  display: flex;
  flex-grow: 1;
  padding: 64px;
  box-sizing: border-box;
  justify-content: space-between;
  @media (min-width: 899px) {
    justify-content: space-between;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 16px;
  }
`;
export const TopColumnDiv = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 900px) {
    width: 100%;
  }
`;
export const TopHeadingDiv = styled.div`
  font-size: 64px;
  font-weight: 500;
  line-height: 86px;
  text-align: left;
  display: flex;
  align-items: center;
  color: #231F20;
  margin-bottom: 18px;
  @media (max-width: 1080px) {
    font-size: 60px;
    line-height: 80px;
  }
  @media (max-width: 900px) {
    font-size: 42px;
    line-height: 56px;
  }
`;
export const TopText1Div = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
  display: flex;
  align-items: center;
  color: #4f4c4d;
  @media (max-width: 1080px) {
    font-size: 20px;
    line-height: 30px;
  }
`;
export const ImagesDiv = styled.div`
  display: flex;
  gap: 8px;
  width: 48%;
  flex-shrink: 0;
  @media (max-width: 900px) {
    min-height: 350px;
    width: 100%;
    flex-grow: 1;
    margin-top: 30px;
  }
`;
export const ImagesLeftDiv = styled.div`
  width: 50%;
  flex-shrink: 0;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-image: url("/image-1.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
`;
export const ImagesRightDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const ImagesRightTopDiv = styled.div`
  width: 100%;
  height: 50%;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/image-2.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
`;
export const ImagesRightBottomDiv = styled.div`
  width: 100%;
  height: 50%;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/image-3.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
`;
export const MiddleSectionDiv = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 64px;
`;
export const MiddleHeadingDiv = styled.div`
  font-size: 56px;
  font-weight: 500;
  line-height: 86px;
  @media (max-width: 900px) {
    font-size: 36px;
    line-height: 56px;
  }
`;
export const Line1Div = styled.div`
  width: 1px;
  background-color: #ededed;
  margin-top: 56px;
  @media (max-width: 900px) {
    display: none;
  }
`;
export const Line2Div = styled.div`
  width: 1px;
  background-color: #ededed;
  margin-top: 56px;
  @media (max-width: 1080px) {
    display: none;
  }
`;
export const Line3Div = styled.div`
  width: 1px;
  background-color: #ededed;
  margin-top: 56px;
  @media (min-width: 1079px) {
    display: none;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;
export const MiddleWrapDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
export const MiddleIconDiv = styled.div`
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 56px;
  padding: 0px 60px;
  box-sizing: border-box;
  @media (max-width: 1080px) {
    width: 49%;
  }
  @media (max-width: 900px) {
    width: 100%;
    padding: 0px 10px;
  }
`;
export const IconDiv = styled.div`
  height: 95px;
  width: 95px;
  border-radius: 95px;
  background: #231F20;
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    height: 51px;
    width: 51px;
  }
`;
export const IconText1Div = styled.div`
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  margin: 32px 0px 24px 0px;
`;
export const IconText2Div = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  color: #848282;
  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 28px;
  }
`;
export const BottomContentDiv = styled.div`
  min-height: calc(100vh - 88px);
  display: flex;
  flex-grow: 1;
  padding: 64px;
  box-sizing: border-box;
  @media (min-width: 899px) {
    justify-content: space-between;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 30px 16px;
  }
`;
export const ImageBottomDiv = styled.div`
  width: 48%;
  flex-shrink: 0;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-image: url("/image-4.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 900px) {
    width: 100%;
    min-height: 328px;
  }
`;
export const BottomColumnDiv = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 900px) {
    width: 100%;
  }
`;
export const BottomHeadingDiv = styled.div`
  font-size: 56px;
  font-weight: 500;
  line-height: 86px;
  text-align: left;
  @media (max-width: 900px) {
    font-size: 40px;
    line-height: 56px;
    margin-top: 24px;
  }
`;
export const BottomText1Div = styled.div`
  font-size: 24px;
  line-height: 40px;
  color: #4f4c4d;
  text-align: left;
  margin: 24px 0px 56px 0px;
  @media (max-width: 1080px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 30px;
  }
`;
export const BottomText2Div = styled.div`
  font-size: 18px;
  line-height: 30px;
  text-align: left;
`;
export const BottomListWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const BottomListDiv = styled.div`
  border: 1px solid #eae9f2;
  border-radius: 5px;
  padding: 17px 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
`;
export const BottomIconImg = styled.img`
  height: 35px;
  width: 35px;
  margin-right: 16px;
`;
export const ContactUsA = styled.a`
  height: 60px;
  width: 292px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  color: #FAFBFD;
  background: #231F20;
  margin: 60px 0px 0px 0px;
  cursor: pointer;
  text-decoration: none;
  @media (max-width: 900px) {
    margin: 30px 0px 30px 0px;
  }
`;